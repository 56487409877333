import React from "react"
import Header from "../components/header/header"
import "../../styles/about.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../styles/contact.scss"
import contactIcon1 from "../images/instagram-color.svg"
import contactIcon2 from "../images/facebook-color.svg"
import contactIcon3 from "../images/mail-color.svg"
import { Helmet } from "react-helmet"
import { useForm, ValidationError } from "@formspree/react"
import ThankYou from "./thanks"

function Contact() {
  const [state, handleSubmit] = useForm("mleoplpr")
  if (state.succeeded) {
    return <ThankYou />
  }
  return (
    <Layout>
      <Header />
      <SEO title="Contact" />
      <Helmet>
        <meta
          property="description"
          content="A wedding planning company that offers guidance to help you with every step of your big day, from your engagement party to your vows. Contact us today to help you clarify your vision! All packages are customized to fit your needs."
        />
      </Helmet>
      <section className="contact-page">
        <div className="contact-text-wrapper">
          <h2 className="contact-sub-heading">Let's connect</h2>
          <h1 className="contact-heading">Get in Touch</h1>
          <p className="contact-body">
            We would love to hear from you! For more information on our event
            and wedding planning services, kindly fill out the contact form and
            we will get back to you!
          </p>
          <ul className="contact-social-media-list">
            <li className="contact-social-media-list-item">
              <img
                alt="instagram icon"
                className="contact-icon"
                src={contactIcon1}
              />
              <a
                className="contact-social-media-list-link"
                href="https://www.instagram.com/yesinspiredevents/"
              >
                @YESInspiredEvents
              </a>
            </li>
            <li className="contact-social-media-list-item">
              <img
                alt="facebook icon"
                className="contact-icon"
                src={contactIcon2}
              />
              <a
                className="contact-social-media-list-link"
                href="https://www.facebook.com/yesinspiredevents"
              >
                YESInspired
              </a>
            </li>
            <li className="contact-social-media-list-item">
              <img
                alt="pinterest icon"
                className="contact-icon"
                src={contactIcon3}
              />
              <a
                className="contact-social-media-list-link"
                href="mailto:info@yesinspired.ca"
              >
                info@yesinspired.ca
              </a>
            </li>
          </ul>
        </div>
        <div className="contact-form-wrapper">
          <form onSubmit={handleSubmit}>
            <label className="form-label" htmlFor="name">
              Full name
            </label>
            <input
              type="text"
              className="form-input"
              name="name"
              placeholder="eg. Your Name"
            />
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              className="form-input"
              name="email"
              placeholder="eg. email@domain.com"
            />
            <label className="form-label" htmlFor="number">
              Phone number
            </label>
            <input
              type="tel"
              className="form-input"
              name="number"
              placeholder="eg. 647-456-9870"
            />
            <label className="form-label" htmlFor="date">
              Date
            </label>
            <input type="date" className="form-input" name="date" />
            <label className="form-label" htmlFor="contact">
              What is your preferred method of contact?
            </label>
            <input
              type="text"
              className="form-input"
              name="name"
              placeholder="Phone or email?"
            />
            <label className="form-label" htmlFor="type">
              What type of event are you trying to host?
            </label>
            <select className="form-input" name="type">
              <option value="" selected disabled hidden>
                Select an option
              </option>
              <option value="">Consultation</option>
              <option value="">Corporate</option>
              <option value="">Curated Boxes</option>
              <option value="">Design & Decor</option>
              <option value="">Social</option>
              <option value="">Wedding</option>
              <option value="">Other</option>
            </select>
            <label className="form-label" htmlFor="details">
              Tell us about the details!
            </label>
            <textarea
              className="form-input text-area"
              name="details"
              rows="6"
              cols="50"
              placeholder="Any spcial details you'd like to share with us!"
            />
            <label className="form-label" htmlFor="refer">
              How did you hear about us?
            </label>
            <input
              type="text"
              className="form-input"
              name="refer"
              placeholder="Google, Referral, Wedding Wire, etc."
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
            <button className="submit-button">Submit</button>
          </form>
        </div>
        <SEO title="Contact" />
      </section>
    </Layout>
  )
}

export default Contact
